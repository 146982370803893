<template>
  <div >
    <el-row>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handlePointAdd">{{$t('Add')+$t('ChargingPoint')}}</el-button>
      <div style="float: right;">
        <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.stationId" :placeholder="$t('All')+' '+$t('ChargingStation')" size="small" class="filter-item" clearable>
          <el-option
                v-for="item in this.chargingStations"
                :label="item.name"
                :key="item.id"
                :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="listQuery.enabled" size="small" class="filter-item">
          <el-option v-bind:label="$t('EnableStatus')" :value="true" />
          <el-option v-bind:label="$t('Disable')+$t('Status')" :value="false" />
        </el-select>
        <el-select v-model="listQuery.enabledOcpi" size="small" class="filter-item">
          <el-option v-bind:label="'OCPI'+$t('Enable')" :value="true" />
          <el-option v-bind:label="'OCPI'+$t('NotEnabled')" :value="false" />
        </el-select>
        <el-input size="small" v-model="listQuery.name" v-bind:placeholder="$t('Name')" class="filter-item" clearable />
      </div>
    </el-row>

    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
     >
      <el-table-column align="center" prop="name" v-bind:label="$t('Name')" sortable />
      <el-table-column align="center" prop="station.service.name" :label="$t('Operator')" v-if="serviceList.length>0" min-width="80%" />
      <el-table-column align="center" prop="charging_points.station_id" v-bind:label="$t('ChargingStation')" sortable="custom">
        <template slot-scope="scope">
             {{ scope.row.station.name }}
          </template>
      </el-table-column>
      <el-table-column align="center" prop="enabled" v-bind:label="$t('EnableStatus')" min-width="50%">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.enabled" type="danger"> {{$t('Enable')}} </el-tag>
                <el-tag v-else type="success"> {{$t('Disable')}} </el-tag>
            </template>
      </el-table-column>
      <el-table-column align="center" prop="ocppIsConnected" v-bind:label="$t('Connect')" min-width="50%">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.ocppIsConnected" type="danger"> {{$t('Yes')}} </el-tag>
                <el-tag v-else type="success"> {{$t('No')}} </el-tag>
            </template>
      </el-table-column>
      <el-table-column align="center" prop="ocppVersion" v-bind:label="$t('OCPPVersion')" min-width="65%"/>
      <el-table-column align="center" prop="numberOfConnectors" v-bind:label="$t('ChargingConnectorNum')" min-width="60%"/>
      <el-table-column align="center" prop="ratedPowerOutput" v-bind:label="$t('RatedOutputPower')" min-width="60%"/>
      <el-table-column  align="center" prop="createTime" v-bind:label="$t('Create')+$t('Date')" :show-overflow-tooltip="true" min-width="60%">
          <template slot-scope="scope">
             {{ parseDateTime(scope.row.createTime) }}
          </template>
      </el-table-column>
      <el-table-column align="center" v-bind:label="$t('Install')" min-width="60%">
          <template slot-scope="scope">
             <el-button type="success" size="mini" icon="el-icon-upload" v-if="!scope.row.station.enabledOCPI" @click="showPointProvisionInfo(scope.row)"/>
          </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
          <template slot-scope="scope">
             <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showPointSetting(scope.row)"/>
             <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" v-if="!scope.row.station.enabledOCPI" @click="handlePointDelete(scope.$index, scope.row)" />
          </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <PointSetting :dialogPointVisible="dialogPointSettingVisible" :pointData="pointData" :serviceList="serviceList" @close="dialogPointSettingOnClose()" />
    <PointProvisionInfo :dialogProvisionVisible="dialogPointProvisionInfoVisible" :provisionInfoData="provisionInfoData" @close="dialogPointProvisionInfoOnClose()" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import PointSetting from './PointSetting.vue'
import PointProvisionInfo from './PointProvisionInfo.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";

export default {
  name: 'Point',
  components: {
    Pagination,
    PointSetting,
    PointProvisionInfo
  },
  mixins: [mixins],
  data() {
    return {
      result: '',
      dialogWidth: 0,
      total: 0,
      list: [],
      serviceList: [],
      chargingStations: this.$store.state.chargingStations,
      listLoading: false,
      listQuery: {
        serviceId: undefined,
        enabled: true,
        enabledOcpi: false,
        name: undefined,
        stationId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      pointData: undefined, // 充電樁資訊
      provisionInfoData: undefined, // 充電樁供裝資訊
      dialogPointSettingVisible: false,
      dialogPointProvisionInfoVisible: false,
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.serviceId': function () {
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.listQuery.serviceId)
        /* 因為route傳過來的serviceId等同讓listQuery.serviceId發生變化,為了避免listQuery.stationId在這被洗成undefined 因此加上判斷 */
        if(this.$route.params.stationId != undefined) { 
          this.listQuery.stationId = Number(this.$route.params.stationId) //若沒有轉成數值,那el-select選單無法以key導入使之正確呈現名稱
          this.$route.params.stationId = undefined 
        } else  
          this.listQuery.stationId = undefined
        /*--------------------------------------------------------------------------------------------------------------------*/
    },
    'listQuery.enabled': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.enabledOcpi': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.name': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.stationId': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
  },
  created() {
    /* Dashboard.vue route過來的參數 by企分會議需求 */
    if(this.$route.params.serviceId != undefined) this.listQuery.serviceId = Number(this.$route.params.serviceId)
    if(this.$route.params.stationId != undefined) this.listQuery.stationId = Number(this.$route.params.stationId)    
    /* Dashboard.vue route過來的參數 by企分會議需求 */
    
    this.dialogWidth = this.setDialogWidth()
    this.resetPage(this.getList)
    this.getServiceOptions()
    //refreshToken();
  },
  beforeDestroy() {
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/point', {params: this.listQuery}).then(res=> {
        this.list = res.data.content
        this.total = res.data.totalElements
        this.listLoading = false
      })
    },
    getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            this.serviceList = res.data
            if (this.serviceList.length==0) {
                this.listQuery.serviceId = this.$store.state.currentAccount.serviceInfo.id //this.list[0].station.service.id
                this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.listQuery.serviceId)
            }
        })
    },
    handlePointAdd() {
      this.dialogPointSettingVisible = true
    },
    showPointSetting(row) {
      this.dialogPointSettingVisible = true
      this.pointData = row
    },
    showPointProvisionInfo(row) {
      this.dialogPointProvisionInfoVisible = true
      this.provisionInfoData = row
    },
    handlePointDelete(index, row) {
      const confirmText = ['確定刪除充電樁 - ' + row.name + ' ?','(請確認此充電樁已無納管任何充電槍)']
      const newDatas = []
      const h = this.$createElement
      for (const i in confirmText) {
        newDatas.push(h('p', null, confirmText[i]))
      }
      this.$confirm('提示', {
        message: h('div', null, newDatas),
        //message: '確定刪除充電樁 - ' + row.name + ' ?',
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delPoint(index, row.id)
      }).catch(()=> {
        console.log('cancel delete point')
      })
    },
    async delPoint(index, id) {
      this.axios.delete('/api/v1/point/' + id).then(()=> {
        this.$store.dispatch("getPointByCurrentAccount") // 更新所有充電樁資訊
        this.$delete(this.list, index);
        this.$message({showClose: true,message: '刪除成功',type: 'success'})
      }).catch(err => {
        this.$message({message: '刪除失敗 ('+err.data+')',type: 'error'})
      })
    },
    dialogPointSettingOnClose() {
      this.dialogPointSettingVisible = false
      this.pointData = undefined
      this.resetPage(this.getList)
    },
    dialogPointProvisionInfoOnClose() {
      this.dialogPointProvisionInfoVisible = false
      this.provisionInfoData = undefined
      this.resetPage(this.getList)
    },
  }
}
</script>
