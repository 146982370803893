<template>
    <el-dialog width="600" v-bind:title="$t('Install')+' '+$t('Information')" :visible="dialogProvisionVisible" @close="dialogProvisionInfoOnClose()">
        <el-row type="flex" justify="start" align="middle">
            {{ ocpp_url }}
        </el-row>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'

export default {
    name: 'PointProvisionInfo',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        provisionInfoData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogProvisionVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            ocpp_url: "",
        };
    },
    watch: {
        provisionInfoData(val) {
            if (val) {
                //this.axios.get('/api/v1/point/getChargingPointProvisionInfo/'+val.id).then(res=> {
                    //this.ocpp_url = res.data.ocpp_url
                //})
                // this.ocpp_url = "ws://"+val.id+":"+val.ocppBasicAuth+"@"+val.station.ocppServerEndpoint+"/OCPP/"+val.id;
                // 202407 自動產生ocpp server URL
                if (val.station.ocppServerEndpoint==null||val.station.ocppServerEndpoint=='')
                    this.ocpp_url = "wss://evems.cht.com.tw/ocpp/"+val.station.service.id+"/"+val.station.id+"/OCPP/"+val.id;
                else
                    this.ocpp_url = val.station.ocppServerEndpoint+"/"+val.id;
                // 202406 ocpp server供裝流程調整
                // this.ocpp_url = val.ocppServer.ocppURL+"/"+val.id;
            }
        }
    },
    methods: {
        dialogProvisionInfoOnClose() {
            this.$emit('close') // 觸發父層 close
        },
    }
}
</script>